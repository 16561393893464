const GE = {
  signUp: 'რეგისტრაცია',
  logIn: 'შესვლა',
  logout: 'გამოსვლა',
  iframeError: 'თქვენს ბრაუზერს არ აქვს iframes-ის მხარდაჭერა!',
  firstStep: {
    title: 'ანგარიშის ტიპი',
    profileType: 'ინდივიდუალური',
    alreadyHaveAnAccount: 'უკვე გაქვს ანგარიში?',
  },
  secondStep: {
    title: 'ფორმის შევსება',
    passwordPlaceHolder: 'შეიყვანეთ 6-დან 12 სიმბოლო',
  },
  thirdStep: {
    title: 'ტელეფონის გადამოწმება',
    codeVerificationPlaceHolder: 'კოდი გამოგზავნილია ნომერზე',
    resendCode: 'კოდის ხელახლა გაგზავნა',
    label: 'შეავსეთ ტელეფონის დამადასტურებელი კოდი',
    timerTitle: 'შეგიძლიათ ისევ მოითხოვოთ კოდი {{time}}-ის შემდეგ',
  },
  signIn: {
    title: 'შესვლა',
    forgotPassword: 'პაროლი დაგავიწყდა?',
    dontHaveAnAccaunt: 'არ გაქვთ ანგარიში?',
    contactUs: 'გთხოვთ, დაგვიკავშირდეთ პაროლის აღსადგენად',
  },
  btns: {
    continue: 'გაგრძელება',
    confirm: 'დაადასტურეთ',
    yes: 'დიახ',
    no: 'არა',
    cancel: 'გაუქმება',
  },
  menu: {
    exchange: 'გადაცვლა',
    kycVerified: 'KYC შემოწმება დასრულებულია',
    kycNotVerified: 'KYC შემოწმება არ არის დასრულებული',
    orders: 'შეკვეთები',
    support: 'მხარდაჭერა',
  },
  ordersTable: {
    index: '№',
    status: 'სტატუსი',
    createdAt: 'თარიღი',
    paymentMethod: 'გადახდის მეთოდი',
    recieveAmount: 'მისაღები თანხა',
    paidAmount: 'გადახდილი თანხა',
  },
  downloadStatement: 'ამონაწერის ჩამოტვირთვა',
  downloadElectronicReceipt: 'ელექტრონული ქვითრის ჩამოტვირთვა',
  order: {
    title: 'შეკვეთა',
    makePayment: 'გადახდა შესაძლოა',
    paymentDetail: 'გადახდის დეტალები',
    officeInfo: 'ოფისის ინფორმაცია',
    wallet: 'საფულის ნომერი',
    amount: 'თანხა',
    currency: 'ვალუტა',
    alert: {
      message: 'მნიშვნელოვანი',
      description: 'თქვენ უნდა გადაიხადოთ წინა ეტაპზე მითითებული საფულედან',
    },
    alertNewInfo: {
      message: 'შეკვეთა შექმნილია',
      description:
        'ჩვენ ველოდებით თქვენს გადახდას. გადაიხადეთ {{time}} წუთის განმავლობაში, შემდეგ დააჭირეთ ღილაკს დადასტურება',
    },
    alertWalletReviewInfo: {
      message: 'მიმდინარეობს თქვენი საფულის შემოწმება',
      description:
        'ჩვენ ვამოწმებთ თქვენს საფულეს. საფულის შემოწმების დრო 30 წუთამდე გრძელდება. გადამოწმების შემდეგ, შეგიძლიათ გააგრძელოთ თქვენი შეკვეთის შექმნა.',
    },
    alertWalletDeclinedInfo: {
      message: 'თქვენი საფულე უარყოფილია',
      description: 'თქვენს საფულეს ცუდი ისტორია აქვს',
    },
    alertWalletVerifiedInfo: {
      message: 'თქვენმა საფულემ გაიარა ვერიფიცირება',
      description: 'შეგიძლიათ გააგრძელოთ',
    },
    address: 'მისამართი',
    phone: 'ტელეფონი',
    email: 'ელფოსტა',
    workingHours: 'ნახეთ სამუშაო საათები',
    bankName: 'ბანკის სახელი',
    iban: 'IBAN კოდი',
    recipientName: 'მიმღების სახელი',
    paymentPurpose: 'გადახდის დანიშნულება',
    paymentForOrder: 'შეკვეთის გადახდა',
    buyAlert: {
      message: 'მნიშვნელოვანი:',
      description:
        'გადახდა უნდა განხორციელდეს თქვენი პირადი საბანკო ანგარიშიდან. ჩვენ ვერ შევძლებთ სხვა ანგარიშიდან გადახდების მიღებას',
    },
    descriptionInProgress:
      'გმადლობთ თქვენი გადახდა. ჩვენ შევამოწმოთ გადახდა და დავადასტუროთ შეკვეთა',
    descriptionExpired:
      'გადახდის ვადა გასვლა. თუ თქვენ შეიძენეთ შეკვეთა, მაგრამ არ დააჭირეთ დადასტურების ღილაკს, ჩვენ დაგეგმებთ დამეკუპვას',
  },
  supportTitles: {
    phone: 'ტელეფონი',
    email: 'ელფოსტა',
  },
  settings: {
    mainInfo: {
      title: 'ძირითადი ინფორმაცია',
      firstName: 'სახელი',
      phone: 'ტელეფონი',
      lastName: 'გვარი',
      birthday: 'დაბადების თარიღი',
    },
    statistic: {
      title: 'სტატისტიკა',
      sold: 'თქვენ გაყიდეთ',
      bought: 'თქვენ შეიძენეთ',
      btnMore: 'Მაჩვენე ყველა',
    },
    actions: {
      title: 'მოქმედებები',
    },
    kycVerify: {
      alert: 'თქვენ არ გაქვთ უფლება იყიდოთ ან გაყიდოთ კრიპტო. გთხოვთ გაიაროთ KYC!',
      btnKycVerification: 'მიიღეთ KYC ვერიფიკაცია',
      helper: 'პროცედურის გავლას სჭირდება 3 წუთი, ამისათვის დაგჭირდებათ თქვენი პასპორტი!',
    },
  },
  inputLabels: {
    phoneNumber: 'ტელეფონი',
    password: 'პაროლი',
    confirmPassword: 'პაროლის გამეორება',
    country: 'Country_ge',
    termsAndConditions: 'ვეთანხმები წესებსა და პირობებს',
    cryptocurrencyConfirm:
      'ვადასტურებ, რომ მესმის  ვირტუალური აქტივების კონცეფცია და ვირტუალური აქტივების გაცვლის სერვისების გამოყენების პროცესი',
    policyStart: 'ვეთანხმები ',
    privacy: 'კონფიდენციალურობის პოლიტიკას, ',
    KYCAML: 'KYC/AML პოლიტიკას, ',
    refund: 'თანხის დაბრუნების პოლიტიკას, ',
    policyEnd: 'ვადასტურებ, რომ არ ვარ PEP (პოლიტიკურად მნიშვნელოვანი პირი), არ ვარ აშშ-ს მოქალაქე',
    verificationCode: 'შეავსეთ ტელეფონის დამადასტურებელი კოდი',
  },
  inputRulesMessages: {
    phoneNamber: 'გთხოვთ შეიყვანოთ თქვენი ტელეფონის ნომერი!',
    password: 'გთხოვთ შეიყვანოთ თქვენი პაროლი!',
    // todo
    confirmPassword: 'Please confirm your password!_ge',
    confirmPasswordDontMatch: 'თქვენ მიერ შეყვანილი ახალი პაროლი არ ემთხვევა!',
    country: 'Please select country!_ge',
    termsAndConditions: 'უნდა დაეთანხმოთ წესებს და პირობებს',
    cryptocurrencyConfirm: 'თქვენ უნდა დაეთანხმოთ დასტურს',
    policy: 'უნდა დაეთანხმოთ ყველა პოლიტიკას',
  },
  exchange: {
    buy: 'კრიპტო ვალუტის შეძენა',
    sell: 'კრიპტო ვალუტის გაყიდვა',
    paymentMethod: {
      title: 'როგორ გსურთ გადაიხადოთ კრიპტოს საფასური',
      titleSell: 'აირჩიეთ თანხის მიღების მეთოდი',
      online: 'ონლაინ',
      offline: 'ოფლაინ',
    },
    address: {
      title: 'აირჩიეთ თქვენთვის სასურველი გადამცვლელი ოფისის მისამართი',
      address: 'მისამართი',
      phone: 'ტელეფონი',
      email: 'ელფოსტა',
      workingHours: 'ნახეთ სამუშაო საათები',
    },
    youBuy: 'თქვენ მიიღებთ',
    youSell: 'თქვენ ყიდით',
    youSpend: 'თქვენ ყიდით',
    conversionFee: 'კონვერტაციის საფასური',
    convertibleAmount: 'კონვერტირებადი თანხა',
    exchangeRate: 'კურსი',
    paymentDetails: {
      title: 'შეავსეთ თქვენი გადახდის დეტალები',
      fullName: 'თქვენი სახელი და გვარი',
      iban: 'თქვენი ბანკის ანგარიშის ნომერი IBAN',
    },
    sellWallet: 'მე ვაგზავნი ამ საფულედან',
    buyWallet: 'შეიყვანეთ თქვენი საფულის მისამართი',
    walletHelper: 'ვალუტა გადაირიცხება ამ საფულეში',
    kycAlertDescription: 'გადაცვლის განსახორციელებლად საჭიროა თქვენი ანგარიშის ვერიფიცირება',
    kycAlertMessage: 'KYC შემოწმება არ არის დასრულებული',
    btnKycAlert: 'მიიღეთ KYC ვერიფიკაცია',
  },
  modals: {
    leave: {
      title: 'ნამდვილად გსურთ გასვლა?',
      description: 'მონაცემები არ იქნება შენახული',
    },
    exchangeCheckOrderinformation: {
      youGet: 'თქვენ მიიღებთ',
      youSell: 'თქვენ ყიდით',
      conversionFee: 'კონვერტაციის საფასური (შესულია)',
      exchangeRate: 'კურსი',
      title: 'შეამოწმეთ ინფორმაცია',
    },
    workingHoursTitle: 'კრიპტოვალუტის გადამცვლელი ოფისის გახსნის საათები',
  },
  statuses: {
    new: 'ახალი',
    completed: 'დასრულებული',
    expired: 'ვადა ამოიწურა',
    'in progress': 'მუშავდება',
    cancelled: 'გაუქმებული',
  },
};

export default GE;
