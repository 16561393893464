import { ConfigProvider, type AppProps } from 'antd';
import { Suspense, type FC, useLayoutEffect } from 'react';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import kaGE from 'antd/locale/ka_GE';
import { useTranslation } from 'react-i18next';
import RouterApp from './navigation/RouterApp';
import history from './libs/history';
import antdTheme from './constants/configAntdTheme';
import Spiner from './components/Spiner';
import api from './api';
import useAuthStore from './store/auth';

const App: FC<AppProps> = () => {
  const { i18n } = useTranslation();
  const setUserLocale = useAuthStore(({ setLocale }) => setLocale);
  const userProfile = useAuthStore(({ profile }) => profile);
  const token = localStorage.getItem('accessToken');

  useLayoutEffect(() => {
    if (
      token &&
      userProfile.language_code !== null &&
      userProfile.language_code !== i18n.language
    ) {
      i18n.changeLanguage(userProfile.language_code);
    }
  }, [token, userProfile.language_code]);

  useLayoutEffect(() => {
    api.setLocale(i18n.language);
    if (token && userProfile.language_code !== i18n.language) setUserLocale(i18n.language);
  }, [i18n.language]);

  return (
    <ConfigProvider
      theme={antdTheme}
      locale={i18n.language === 'ka' ? kaGE : undefined}
    >
      <Suspense fallback={<Spiner loading />}>
        {/* @ts-expect-error: wrong history type */}
        <HistoryRouter history={history}>
          <RouterApp />
        </HistoryRouter>
      </Suspense>
    </ConfigProvider>
  );
};

export default App;
