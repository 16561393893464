/* eslint-disable no-promise-executor-return, consistent-return, import/no-cycle */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
import axios, { type AxiosError, type AxiosInstance, type AxiosResponse } from 'axios';

import endpoints from './endpoints';
import history from '../libs/history';
import openNotification from '../libs/tooltip';
import useAuthStore from '../store/auth';

let refreshing = false;
let failedQueue: any[] = [];

const checkUrlForUpdateToken = (url: string): boolean => {
  const urlsList = [
    '/auth/login',
    '/auth/refresh',
    '/auth/logout',
    '/auth/verify-phone',
    '/countries',
  ];
  return urlsList?.includes(url);
};

const checkErrorStatusForUpdate = (status: number): boolean => {
  const statusesList = [401, 400, 415];
  return statusesList?.includes(status);
};

const checkErrorStatusForLogout = (status: number): boolean => {
  const statusesList = [403];
  return statusesList?.includes(status);
};

const checkErrorMessageForLogout = (message: any): boolean => {
  return message.data.data.message !== 'Wrong reset password code';
};

const processQueue = (error: unknown, token: string | null): void => {
  for (const requestPromise of failedQueue) {
    if (error) {
      requestPromise.reject(error);
    } else {
      requestPromise.resolve(token);
    }
  }

  failedQueue = [];
};

class Api {
  token: string;
  axios: AxiosInstance;
  endpoints: any;

  constructor() {
    this.token = localStorage.getItem('accessToken') ?? '';
    this.axios = axios.create({
      headers: {
        Accept: 'application/json',
      },
      baseURL: process.env.REACT_APP_BASE_URL,
    });
    this.endpoints = Object.fromEntries(
      endpoints.map(({ name, Endpoint }) => [name, new Endpoint(this.axios)]),
    );
    this.setBearer(this.token);
    this.init();
  }

  public setBearer = (token: string): void => {
    this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  };

  public setLocale = (value: string): void => {
    this.axios.defaults.headers.common.locale = value;
  };

  public removeBearer = (): void => {
    delete this.axios.defaults.headers.common.Authorization;
  };

  refreshAccesstoken = async (refreshToken: string): Promise<string> => {
    return await axios
      .post(
        '/auth/refresh',
        {},
        {
          baseURL: process.env.REACT_APP_BASE_URL,
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        },
      )
      .then(async response => {
        const { data } = response.data;
        localStorage.setItem('accessToken', data.access_token);
        this.setBearer(data.access_token);
        return data.access_token;
      })
      .catch(() => {
        this.logoutAction();
      });
  };

  logoutAction = (): void => {
    localStorage.clear();
    this.removeBearer();
    history.replace('/login');
    useAuthStore.persist.clearStorage();
  };

  fireLogout = (): void => {
    /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
    axios
      .post(
        '/auth/logout',
        {},
        {
          baseURL: process.env.REACT_APP_BASE_URL,
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      )
      .finally(() => {
        this.logoutAction();
      });
  };

  init = (): void => {
    this.axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: AxiosError) => {
        const originalRequest = error.config;
        if (
          error.response &&
          error.response.status === 401 &&
          originalRequest?.url?.includes('/logout')
        ) {
          this.logoutAction();
          return;
        }

        if (
          !originalRequest ||
          (originalRequest?.url && checkUrlForUpdateToken(originalRequest.url))
        ) {
          openNotification(`Something went wrong with ${originalRequest?.url}`, error.message);
          throw error;
        }

        if (error.response?.status && checkErrorStatusForUpdate(error.response.status)) {
          if (refreshing) {
            return await new Promise((resolve, reject) =>
              failedQueue.push({ resolve, reject }),
            ).then(async token => {
              originalRequest.headers.Authorization = token as string;
              return await this.axios(originalRequest);
            });
          }

          refreshing = true;
          const refreshToken = localStorage.getItem('accessToken') ?? '';

          return await new Promise((resolve, reject) => {
            this.refreshAccesstoken(refreshToken)
              .then((accessToken: string) => {
                originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                processQueue(null, accessToken);
                resolve(axios(originalRequest));
              })
              .catch(refreshError => {
                processQueue(refreshError, null);
                reject(refreshError);
              })
              .finally(() => {
                refreshing = false;
              });
          });
        }
        console.log(error);
        if (
          error.response &&
          checkErrorStatusForLogout(error.response.status) &&
          checkErrorMessageForLogout(error.response)
        ) {
          this.fireLogout();
        }

        throw error;
      },
    );
  };
}

const api = new Api();

export default api;
/* eslint-enable no-promise-executor-return, consistent-return, import/no-cycle */
