import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { type AxiosResponse } from 'axios';
/* eslint-disable-next-line  import/no-cycle */
import api from '../api';
import history from '../libs/history';
import { type Login, type Registration, type User } from '../interfaces/requestAuthTypes';
import type userAuth from '../interfaces/userAuth';
import type Profile from '../interfaces/Profile';
import openNotification from '../libs/tooltip';
// стор для хранения информации о регистации, авторизации и пользователе

interface AuthCountry {
  id: number;
  name: string;
  code: string;
  phone_code: number;
}

interface ReturnError {
  data: any;
  error: {
    advanced: Record<string, string[]>;
    message: string;
  };
  status: number;
}
interface AuthStoreProperties {
  roles: string[];
  user: { id: number; name: string; phone: string; required_change_password: boolean };
  profile: Profile;
  authCountries: AuthCountry[];
  auth: (data: userAuth) => undefined | ReturnError;
  logout: VoidFunction;
  registration: (data: userAuth) => string | ReturnError;
  verification: (data: {
    phone: string;
    uuid: string;
    prefix: string;
    code: string;
  }) => undefined | ReturnError;
  resendCodeVerify: (phone: string) => string;
  resendCodeVerifyResetPassword: (phone: string) => string | ReturnError;
  resetPassword: (data: { code: string | undefined; new_password: string }) => string;
  changePassword: (data: {
    current_password: string;
    new_password: string;
    confirm_new_password: string;
  }) => { message: string };
  getUser: () => void;
  getLongPollingUser: () => AxiosResponse | Profile;
  getAuthCounties: () => void;
  setLocale: (locale: string) => void;
}

const initialState = {
  roles: [],
  user: { id: -1, name: '', phone: '', required_change_password: true },
  profile: {} as Profile,
  authCountries: [],
};

const useAuthStore = create(
  persist<AuthStoreProperties>(
    set => ({
      ...initialState,
      auth: ({ phone, password, prefix }) =>
        api.endpoints.auth
          .authorization({ password, phone: `+${prefix}${phone}` })
          .then((request: { data: Login }) => {
            const { data } = request.data;
            localStorage.setItem('accessToken', data.access_token);
            api.setBearer(data.access_token);
            const auth = {
              roles: data.user.roles,
              user: data.user,
              kyc: false,
            };
            set(auth);
            history.replace('exchange');
          })
          .catch((error: { response: AxiosResponse }) => ({
            error: error.response.data.error,
            status: error.response.status,
          })),
      logout: () => {
        api.endpoints.auth.logout().finally(() => {
          localStorage.removeItem('accessToken');
          api.removeBearer();
          set(initialState);
          history.replace('login');
        });
      },
      registration: ({ phone, password, prefix }) =>
        api.endpoints.auth
          .registration({ password, phone: `+${prefix}${phone}` })
          .then((request: { data: Registration }) => {
            const { data } = request.data;
            return data.uuid;
          })
          .catch((error: { response: AxiosResponse }) => ({
            error: error.response.data.error,
            status: error.response.status,
          })),
      verification: ({ phone, uuid, prefix, code }) =>
        api.endpoints.auth
          .phoneVerify({ code, phone: `+${prefix}${phone}`, uuid })
          .then((request: { data: Login }) => {
            const { data } = request.data;
            localStorage.setItem('accessToken', data.access_token);
            api.setBearer(data.access_token);
            const auth = {
              roles: data.user.roles,
              user: data.user,
              kyc: false,
            };
            set(auth);
            history.replace('exchange');
          })
          .catch((error: { response: AxiosResponse }) => ({
            error: error.response.data.error,
            status: error.response.status,
          })),
      resendCodeVerify(phone) {
        return api.endpoints.auth
          .resendVerificationCode({ phone })
          .then((request: { data: Registration }) => {
            const { data } = request.data;
            return data.uuid;
          })
          .catch((error: { response: AxiosResponse }) => {
            console.log('resendCodeVerify_ERROR', error);
          });
      },
      resendCodeVerifyResetPassword(phone) {
        return api.endpoints.auth
          .resetPasswordPhoneVerify({ phone })
          .then((request: { data: Registration }) => {
            const { data } = request.data;
            return data.message;
          })
          .catch((error: { response: AxiosResponse }) => {
            console.log('resendCodeVerify_ERROR', error);
          });
      },
      resetPassword({ code, new_password }) {
        return api.endpoints.auth
          .resetPassword({ code, new_password })
          .then((request: { data: Registration }) => {
            const { data } = request.data;
            return data.message;
          })
          .catch((error: { response: AxiosResponse }) => {
            console.log('resetPassword_ERROR', error);
          });
      },
      changePassword({ current_password, new_password, confirm_new_password }) {
        return api.endpoints.auth
          .changePassword({ current_password, new_password, confirm_new_password })
          .then((request: { data: Registration }) => {
            const { data } = request.data;
            return data;
          })
          .catch((error: { response: AxiosResponse }) => {
            console.log('changePassword_ERROR', error);
            return error.response.data.data;
          });
      },
      getUser() {
        api.endpoints.auth
          .userInfo()
          .then((request: { data: { data: User } }) => {
            set({ user: request.data.data });
            set({ profile: request.data.data.profile ?? undefined });
          })
          .catch((error: { response: AxiosResponse }) => {
            console.log('getUser_ERROR', error);
          });
      },
      getLongPollingUser() {
        return api.endpoints.settings
          .getLongPollingUser()
          .then((request: { data: { data: Profile } }) => {
            set({ profile: request.data.data ?? undefined });
            if (request.data.data.first_name || request.data.data.last_name)
              set(state => ({
                user: {
                  ...state.user,
                  name: `${request.data.data.first_name} ${request.data.data.last_name}`,
                },
              }));
            return request.data.data;
          })
          .catch((error: { response: AxiosResponse }) => {
            console.log('getUser_ERROR', error);
            return error.response;
          });
      },
      getAuthCounties() {
        api.endpoints.auth
          .getCountry()
          .then((request: { data: { data: { items: AuthCountry[] } } }) => {
            set({ authCountries: request.data.data.items });
          })
          .catch((error: { response: AxiosResponse }) => {
            console.log('getAuthCounties_ERROR', error);
          });
      },
      setLocale(locale) {
        api.endpoints.auth.setLocale({ locale }).then((response: { data: { data: User } }) => {
          if (response.data.data.profile) set({ profile: response.data.data.profile });
        });
      },
    }),
    {
      name: 'authStore',
    },
  ),
);

export default useAuthStore;
