import { type Axios, type AxiosPromise } from 'axios';

class SettingsEndpoint {
  axios: Axios;
  constructor(axios: Axios) {
    this.axios = axios;
  }

  getYouSold: () => AxiosPromise = async () => await this.axios.get('/settings/sold');

  getYouBought: () => AxiosPromise = async () => await this.axios.get('/settings/bought');

  getKycVerificationLink: () => AxiosPromise = async () =>
    await this.axios.get('/sumsub/iframe-link');

  getLongPollingUser: () => AxiosPromise = async () =>
    await this.axios.get('/sumsub/status-polling');
}

export default SettingsEndpoint;
