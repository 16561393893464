import variables from '../assets/scss/variables.scss';

const antdTheme = {
  token: {
    colorPrimary: variables.primary,
    colorTextHeading: variables.black,
    colorLink: variables.primary,
    borderRadius: 2,
    fontFamily: 'Inter',
  },
  components: {
    Layout: {
      headerBg: variables.headerBackground, // цвет header компонента
      siderBg: variables.siderBackground, // цвет сайдбара компонента
    },
    Segmented: {
      itemActiveBg: variables.primary,
      itemSelectedBg: variables.primary,
      itemSelectedColor: 'white',
    },
    Menu: {
      itemSelectedColor: variables.darkGreen,
      itemSelectedBg: `rgba(18,184,131, 0.06)`,
    },
    Modal: {
      titleColor: `rgba(0, 0, 0, 0.85)`, // --character-title-85
    },
  },
};
export default antdTheme;
