import { type Axios, type AxiosPromise } from 'axios';

class ExchangeEndpoint {
  axios: Axios;
  constructor(axios: Axios) {
    this.axios = axios;
  }

  getPaymentMethods: () => AxiosPromise = async () => await this.axios.get('/payment-methods');

  getCurrencies: () => AxiosPromise = async () => await this.axios.get('/currencies');

  getOffices: () => AxiosPromise = async () => await this.axios.get('/exchange-offices');

  calculateRate: (data: object) => AxiosPromise = async data =>
    await this.axios.post('/exchange/calculate-rate', data);

  buyExchange: (data: object) => AxiosPromise = async data =>
    await this.axios.post('/exchange/buy', data);

  sellExchange: (data: object) => AxiosPromise = async data =>
    await this.axios.post('/exchange/sell', data);

  getDefaultCurrencies: () => AxiosPromise = async () =>
    await this.axios.get('/default-currencies');
}

export default ExchangeEndpoint;
