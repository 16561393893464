import { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Table, type TableColumnsType, Typography } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';
import useSettingsStore from '../../../store/settings';
import type SettingsOrder from '../../../interfaces/SettingsOrder';
// todo: не используется но будет, если число валют привысит 6
const { Title } = Typography;

const columns: TableColumnsType<SettingsOrder> = [
  {
    title: '#',
    dataIndex: 'currency_code',
    key: 'currency_code',
    render: (_, { currency_code: CurrencyCode }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <Image
          src={icon}
          alt={'payment method logo'}
          preview={false}
          width={16}
        /> */}
        <p style={{ marginLeft: 8 }}>{CurrencyCode}</p>
      </div>
    ),
  },
  {
    title: '#',
    dataIndex: 'currency_amount',
    key: 'currency_amount',
  },
];

export default function StatisticList(): JSX.Element {
  const youBoughtItems = useSettingsStore(({ youBought }) => youBought);
  const youSoldItems = useSettingsStore(({ youSold }) => youSold);
  const navigate = useNavigate();
  const { state } = useLocation();
  const type = state?.type ?? undefined;
  const [loading, setLoading] = useState(false);
  const [data] = useState(type === 'bought' ? youBoughtItems : youSoldItems);

  useLayoutEffect(() => {
    if (!type) navigate(-1);
  }, [type]);

  const loadMore = (): void => {
    if (loading) return;
    setLoading(true);
    setTimeout(() => {
      // setData([...data, ...BOUGHT.slice(10)]);
      setLoading(false);
    }, 1000);
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.top}
        onClick={() => {
          navigate('/settings');
        }}
      >
        <ArrowLeftOutlined />
        <Title level={3}>You {type}</Title>
      </div>
      {/* todo: для оптимизации возможно стот свой обработчики бесконечного скрола написать, этот с примера антд */}
      <InfiniteScroll
        dataLength={data.length}
        next={loadMore}
        hasMore={data.length < 12}
        loader={<p>Loading...</p>}
      >
        <Table
          columns={columns}
          dataSource={data}
          showHeader={false}
          pagination={false}
        />
      </InfiniteScroll>
    </div>
  );
}
