const EN = {
  signUp: 'Sign Up',
  logIn: 'Log In',
  logout: 'Logout',
  iframeError: 'Your browser does not support iframes!',
  resetPassword: {
    title: 'Reset Password',
    success: {
      title: 'Password reset successfully',
      message: 'You can',
    },
    firstStep: {
      title: 'Confirm your phone',
      alreadyHaveAnAccount: 'Already have an account?',
    },
    secondStep: {
      title: 'Phone verification',
      codeVerificationPlaceHolder: 'Code has been sent to phone number',
      resendCode: 'Resend code',
      changePhone: 'Change phone',
      label: 'Enter the phone verification code',
      timerTitle: 'You can request the code again after',
      button: 'Continue',
    },
  },
  firstStep: {
    title: 'Account type',
    profileType: 'Individual',
    alreadyHaveAnAccount: 'Already have an account?',
  },
  secondStep: {
    title: 'Fill out the form',
    passwordPlaceHolder: 'Enter 6 to 12 characters',
  },
  thirdStep: {
    title: 'Phone verification',
    codeVerificationPlaceHolder: 'Code has been sent to phone number ',
    resendCode: 'Resend code',
    label: 'Enter the phone verification code',
    timerTitle: 'You can request the code again after',
  },
  signIn: {
    title: 'Sign In',
    forgotPassword: 'Forgot password?',
    dontHaveAnAccaunt: "Don't have an account?",
    contactUs: 'Please contact us to reset your password',
  },
  btns: {
    continue: 'Continue',
    confirm: 'Confirm',
    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',
  },
  menu: {
    exchange: 'Exchange',
    kycVerified: 'KYC verified',
    kycNotVerified: 'KYC not verified',
    orders: 'Orders',
    support: 'Support',
  },
  ordersTable: {
    index: '№',
    status: 'Status',
    createdAt: 'Date',
    paymentMethod: 'Payment Method',
    recieveAmount: 'Recieve Amount',
    paidAmount: 'Paid Amount',
  },
  downloadStatement: 'Download statement',
  downloadElectronicReceipt: 'Download electronic receipt',
  order: {
    title: 'Order',
    makePayment: 'Make payment within:',
    paymentDetail: 'Payment detail',
    officeInfo: 'Office info',
    wallet: 'Wallet number',
    amount: 'Amount',
    currency: 'Currency',
    alert: {
      message: 'Important:',
      description: 'you should pay from the wallet specified in the previous step',
    },
    alertNewInfo: {
      message: 'The order has been created',
      description:
        'We are waiting for your payment. Make the payment within {{time}} minutes, then click on the Сonfirm button',
    },
    alertWalletReviewInfo: {
      message: 'Your wallet is being verified',
      description:
        'We are checking your wallet. This usually takes up to 30 minutes. After verification, you can continue creating your order.',
    },
    alertWalletDeclinedInfo: {
      message: 'Your wallet has been declined',
      description: 'Your wallet has a bad history',
    },
    alertWalletVerifiedInfo: {
      message: 'Your wallet has been verified',
      description: 'You can continue',
    },
    alertCommissionReviewInfo: {
      message:
        'The system administrator changed the terms of the order commission. Confirm the changes.',
      description:
        'The system administrator changed the terms of the order commission. Confirm the changes.',
      confirm: 'Confirm',
      decline: 'Decline',
      warning:
        'Are you sure you want to decline the commission changes? The order will change to canceled status',
    },
    address: 'Adress',
    phone: 'Phone',
    email: 'Email',
    workingHours: 'See working hours',
    bankName: 'Bank name',
    iban: 'IBAN',
    recipientName: 'Recipient name',
    paymentPurpose: 'Payment purpose',
    paymentForOrder: 'Payment for order',
    buyAlert: {
      message: 'Important:',
      description:
        'the payment must be made directly from your bank account. We will not be able to accept payment from another account.',
    },
    descriptionInProgress:
      'Thank you for your payment. We will check the payment and confirm the order.',
    descriptionExpired:
      'Payment time has expired. If you paid for the order, but did not click on the Confirm button we will contact you.',
  },
  supportTitles: {
    phone: 'Phone',
    email: 'Email',
  },
  settings: {
    mainInfo: {
      title: 'Main information',
      firstName: 'First name',
      phone: 'Phone',
      lastName: 'Last name',
      birthday: 'Date of Birth',
    },
    statistic: {
      title: 'Statistic',
      sold: 'You sold',
      bought: 'You bought',
      btnMore: 'See more',
    },
    actions: {
      title: 'Actions',
    },
    changePassword: {
      title: 'Change password',
      current: { label: 'Current password', error: 'Please input your current password' },
      password: {
        label: 'New password',
        extra:
          'minimum of 8 characters, 1 uppercase letter, special character, 1 lowercase letter and 1 number',
        error: 'Please input your password',
        errorD: 'Use number',
        errora: 'Use lowercase letter',
        errorA: 'Use uppercase letter',
        errorS: 'Use special character',
        errorL: 'Pinimum of 8 characters',
      },
      confirm: {
        label: 'Confirm new password',
        error: 'Please confirm your password',
        errorConfirm: 'The new password that you entered do not match',
      },
      success: 'The password has been changed',
      fail: 'The password has not been changed. You entered the wrong current password',
    },
    kycVerify: {
      alert: 'You are not eligible to buy or sell crypto. Please pass KYC!',
      btnKycVerification: 'Get KYC verification',
      helper: 'It only takes 3 minutes, you need your passport!',
    },
  },
  inputLabels: {
    phoneNumber: 'Phone',
    password: 'Password',
    confirmPassword: 'Repeat password',
    country: 'Country',
    termsAndConditions: 'I agree to the terms and conditions',
    cryptocurrencyConfirm:
      'I confirm that I understand the concept of cryptocurrency and the process of using cryptocurrency exchange services',
    policyStart: 'I agree to the, ',
    privacy: 'Privacy Policy, ',
    KYCAML: 'KYC/AML Policy, ',
    refund: 'Refund Policy, ',
    policyEnd: 'I confirm that I am not a PEP(Politically Exposed Person), I am not US citizen.',
    verificationCode: 'Enter the phone verification code',
  },
  inputRulesMessages: {
    phoneNamber: 'Please input your phone number',
    password: 'Please input your password',
    confirmPassword: 'Please confirm your password',
    confirmPasswordDontMatch: 'The new password that you entered do not match!',
    country: 'Please select country!',
    termsAndConditions: 'Should accept terms and conditions',
    cryptocurrencyConfirm: 'Should accept confirm',
    policy: 'Should agree with all policy',
  },
  exchange: {
    buy: 'Buy crypto',
    sell: 'Sell crypto',
    paymentMethod: {
      title: 'How you wish to pay your crypto',
      titleSell: 'Choose receive money method',
      online: 'online',
      offline: 'offline',
    },
    address: {
      title: 'Choose the address of the exchange office',
      address: 'Address',
      phone: 'Phone',
      email: 'Email',
      workingHours: 'See working hours',
    },
    youBuy: 'You get',
    youSell: 'You sell',
    youSpend: 'You spend',
    conversionFee: 'Conversion fee',
    convertibleAmount: 'Convertible amount',
    exchangeRate: 'Exchange rate',
    paymentDetails: {
      title: 'Fill your payment details',
      fullName: 'Full name',
      iban: 'IBAN',
    },
    sellWallet:
      'I will send from this wallet. Note that the {{chain}} blockchain is used for the selected cryptocurrency.',
    buyWallet:
      'Your wallet number. Note that the {{chain}} blockchain is used for the selected cryptocurrency.',
    walletHelper: 'Currency will be transferred to this wallet',
    kycAlertDescription: 'You need to verify your account to perform an exchange',
    kycAlertMessage: 'KYC not verified',
    btnKycAlert: 'Get verification',
    changePassword: {
      message: 'Change Password',
      description:
        'Your password was set automatically by the system. You need to change the password to your own',
    },
  },
  modals: {
    leave: {
      title: 'Are you sure you want to cancel this order?',
      description: 'Data will not be saved',
    },
    exchangeCheckOrderinformation: {
      youGet: 'You get',
      youSell: 'You sell',
      conversionFee: 'Conversion fee (included)',
      exchangeRate: 'Exchange rate',
      title: 'Check the information',
    },
    workingHoursTitle: 'Cryptocurrency exchange office opening hours',
    decline: {
      title: 'Decline',
      extra:
        'Are you sure you want to decline the commission changes? The order will change to canceled status',
    },
    change: {
      title: 'Pasword was change',
      extra: '',
    },
  },
  statuses: {
    new: 'NEW',
    completed: 'COMPLETED',
    expired: 'EXPIRED',
    'in progress': 'IN PROGRESS',
    cancelled: 'CANCELLED',
  },
};

export default EN;
