import { type Axios, type AxiosPromise } from 'axios';

class OrdersEndpoint {
  axios: Axios;
  constructor(axios: Axios) {
    this.axios = axios;
  }

  getOrders: (parameters: object) => AxiosPromise = async parameters =>
    await this.axios.get('/orders', { params: parameters });

  getOneOrder: (id: number) => AxiosPromise = async id => await this.axios.get(`/orders/${id}`);

  cancelOrder: (id: number) => AxiosPromise = async id =>
    await this.axios.post(`/orders/${id}/cancel`);

  confirmOrder: (id: number) => AxiosPromise = async id =>
    await this.axios.post(`/orders/${id}/confirm`);

  getConfig: () => AxiosPromise = async () => await this.axios.get(`/config`);

  getDoc: (type: string) => any = async type =>
    await fetch(`${process.env.REACT_APP_BASE_URL}/orders/me/export?type=${type}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

  getRecipte: (id: number) => any = async id =>
    await fetch(`${process.env.REACT_APP_BASE_URL}/orders/${id}/export-pdf`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

  continueOrder: (id: number) => AxiosPromise = async id =>
    await this.axios.post(`/orders/${id}/continue`);

  confirmCommissionsChange: (id: number) => Promise<void> = async id => {
    const data = {
      status: 'WALLET REVIEW',
    };
    await this.axios.post(`/orders/${id}/confirmCommissionsChange`, data);
  };

  declineCommissionsChange: (id: number) => Promise<void> = async id => {
    const data = {
      status: 'WALLET REVIEW',
    };
    await this.axios.post(`/orders/${id}/declineCommissionsDecline`, data);
  };
}

export default OrdersEndpoint;
