import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/scss/index.scss';
import './i18n';
import { BrowserTracing } from '@sentry/react';
import * as Sentry from '@sentry/react';
import App from './App';

const dsn = process.env.REACT_APP_SENTRY_DSN;
if (dsn !== undefined && dsn?.length !== 0)
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1,
  });

const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
