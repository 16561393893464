/* eslint-disable */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import EN from './constants/localisation/languages/en';
import GE from './constants/localisation/languages/ge';

i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: EN,
    },
    ka: {
      translation: GE,
    },
  },
});
export default i18n;

/* eslint-enable */
