import AuthEndpoint from './authEndpoint';
import ExchangeEndpoint from './exchangeEndpoint';
import OrdersEndpoint from './ordersEndpoiont';
import SettingsEndpoint from './settingsEndpoint';

export default [
  { name: 'auth', Endpoint: AuthEndpoint },
  { name: 'exchange', Endpoint: ExchangeEndpoint },
  { name: 'orders', Endpoint: OrdersEndpoint },
  { name: 'settings', Endpoint: SettingsEndpoint },
];
