import { Button, type FormProps, Typography, Form, Input, Modal } from 'antd';

import useAuthStore from '../../../store/auth';
import openNotification from '../../../libs/tooltip';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const { Title } = Typography;

type FieldType = {
  current: string;
  password: string;
  confirm: string;
};

export default function ChangePassword(): JSX.Element {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const change = useAuthStore(({ changePassword }) => changePassword);

  const success = () => {
    Modal.success({
      content: t('settings.changePassword.success'),

      onOk() {
        window.location.reload();
      },
    });
  };

  const fail = () => {
    openNotification(t('settings.changePassword.fail'));
  };

  const onFinish: FormProps<FieldType>['onFinish'] = async values => {
    const data = {
      current_password: values.current,
      new_password: values.password,
      confirm_new_password: values.confirm,
    };
    const response = await change(data);
    if (typeof response === 'undefined') {
      fail();
    } else {
      success();
    }
  };

  const validatePassword = (value: string) => {
    if (value.length === 0) return '';
    if (!/(?=.*\d.*)/.test(value)) return t('settings.changePassword.password.errorD');
    if (!/(?=.*\d.*)(?=.*[a-z].*)/.test(value)) return t('settings.changePassword.password.errora');
    if (!/(?=.*\d.*)(?=.*[a-z].*)(?=.*[A-Z].*)/.test(value))
      return t('settings.changePassword.password.errorA');
    if (!/(?=.*\d.*)(?=.*[a-z].*)(?=.*[A-Z].*)(?=.*[!#\$%&\?].*)/.test(value))
      return t('settings.changePassword.password.errorS');
    if (!/(?=.*\d.*)(?=.*[a-z].*)(?=.*[A-Z].*)(?=.*[!#\$%&\?].*).{8,}/.test(value))
      return t('settings.changePassword.password.errorL');
    return '';
  };

  return (
    <div className={styles.wrapper}>
      <Title level={4}>{t('settings.changePassword.title')}</Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
      >
        <Form.Item
          label={t('settings.changePassword.current.label')}
          name="current"
          rules={[{ required: true, message: t('settings.changePassword.current.error') }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="password"
          label={t('settings.changePassword.password.label')}
          extra={
            form.getFieldError('password').length === 0 &&
            `${t('settings.changePassword.password.extra')}`
          }
          rules={[
            {
              required: true,
              message: t('settings.changePassword.password.error'),
            },
            () => ({
              async validator(_, value) {
                if (validatePassword(value)) throw new Error(validatePassword(value));
                return;
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label={t('settings.changePassword.confirm.label')}
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: t('settings.changePassword.confirm.error'),
            },
            ({ getFieldValue }) => ({
              async validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return;
                }
                throw new Error(t('settings.changePassword.confirm.errorConfirm'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
          >
            {t('settings.changePassword.title')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
