import { create } from 'zustand';

import api from '../api';
import type SettingsOrder from '../interfaces/SettingsOrder';
// стор для хранения информации пренадлежащей странице Settings

interface SettingsInitialProperties {
  youBought: SettingsOrder[];
  youSold: SettingsOrder[];
  loadinng: {
    bought: boolean;
    sold: boolean;
    link: boolean;
  };
}

interface SettingsStoreProperties extends SettingsInitialProperties {
  getYouBought: () => void;
  getYouSold: () => void;
  getKycVerificationLink: () => string;
}

const initialState: SettingsInitialProperties = {
  youBought: [],
  youSold: [],
  loadinng: {
    bought: false,
    sold: false,
    link: false,
  },
};

const useSettingsStore = create<SettingsStoreProperties>(set => ({
  ...initialState,
  getYouBought() {
    set(state => ({ loadinng: { ...state.loadinng, bought: true } }));
    api.endpoints.settings
      .getYouBought()
      .then((response: { data: { data: { items: SettingsOrder[] } } }) => {
        const { items } = response.data.data;
        set({ youBought: items });
      })
      .finally(() => {
        set(state => ({ loadinng: { ...state.loadinng, bought: false } }));
      });
  },
  getYouSold() {
    set(state => ({ loadinng: { ...state.loadinng, sold: true } }));
    api.endpoints.settings
      .getYouSold()
      .then((response: { data: { data: { items: SettingsOrder[] } } }) => {
        const { items } = response.data.data;
        set({ youSold: items });
      })
      .finally(() => {
        set(state => ({ loadinng: { ...state.loadinng, sold: false } }));
      });
  },
  getKycVerificationLink() {
    set(state => ({ loadinng: { ...state.loadinng, link: true } }));
    return api.endpoints.settings
      .getKycVerificationLink()
      .then((response: { data: { url: string } }) => {
        return response.data.url;
      })
      .finally(() => {
        set(state => ({ loadinng: { ...state.loadinng, link: false } }));
      });
  },
}));

export default useSettingsStore;
