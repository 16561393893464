import { Space } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';

export default function Spiner({
  loading,
  style,
}: {
  loading: boolean;
  style?: object;
}): JSX.Element {
  return (
    <Space
      direction="vertical"
      className={styles.spin}
      style={style}
    >
      {loading && <LoadingOutlined className={styles.loading} />}
    </Space>
  );
}
